import type { Workbook, Worksheet } from 'exceljs';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { UploadFile } from '@angular-ex/uploader';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable, MimeType, SheetValidatorService, trackByIndex } from '@supy/common';
import { DialogComponent, IDialogComponent, SnackbarService } from '@supy/components';

import { ManualSyncPosItemRequest, ManualSyncPosItemsRequest } from '../../models';

@Component({
  selector: 'supy-import-pos-items-dialog',
  styleUrls: ['import-pos-items-dialog.component.scss'],
  templateUrl: 'import-pos-items-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportPosItemsDialogComponent extends Destroyable implements IDialogComponent {
  private readonly sheetValidatorService = inject(SheetValidatorService);
  private readonly snackbarService = inject(SnackbarService);
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly submitImport = new EventEmitter<ManualSyncPosItemsRequest>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly mimeType = MimeType;
  protected readonly maxFileSize = 5000000;
  protected readonly trackByIndex = trackByIndex();

  protected readonly workbook = signal<Workbook | null>(null);
  protected readonly sheetItems = signal<ManualSyncPosItemRequest[]>([]);

  protected readonly sheetNames = computed<string[]>(() => this.workbook()?.worksheets.map(({ name }) => name) ?? []);

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onLocalFileUploaded(event: UploadFile[]): void {
    if (!event.length) {
      return;
    }

    this.readSheetData(event.at(0)?.nativeFile as File);
  }

  readSheetData(file: File): void {
    const reader: FileReader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const { Workbook } = (await import('exceljs')).default;
      const workbook = await new Workbook().xlsx.load(e.target?.result as ArrayBuffer);

      this.workbook.set(workbook);
    };

    reader.readAsArrayBuffer(file);
  }

  onSheetSelect(sheetName: string): void {
    const worksheet = this.workbook()?.getWorksheet(sheetName) as Worksheet;

    const [columns, ...data] = worksheet.getRows(1, worksheet.actualRowCount)?.reduce<string[][]>((acc, row) => {
      acc.push((row.values as string[]).slice(1));

      return acc;
    }, []) as string[][];

    try {
      this.sheetValidatorService.validate(data, {
        rowOffset: 1,
        totalColumns: 3,
        columnsOptions: [
          { index: 0, name: columns.at(0) as string, required: true },
          { index: 1, name: columns.at(1) as string, required: true, type: 'string' },
        ],
      });

      this.sheetItems.set(
        data.map(([code, name, category, isModifier]) => {
          let posItem = {
            code: code.toString(),
            name: { en: name },
          } as ManualSyncPosItemRequest;

          if (category) {
            posItem = { ...posItem, metadata: { category } };
          }

          if (isModifier) {
            posItem = { ...posItem, isModifier: Boolean(isModifier) };
          }

          return posItem;
        }),
      );
    } catch (error) {
      this.snackbarService.open((error as Error).message, { variant: 'error' });
    }
  }

  protected onRemoveFile(): void {
    this.workbook.set(null);
    this.sheetItems.set([]);
  }

  protected onSubmit(): void {
    if (!this.sheetItems().length) {
      return;
    }

    this.submitImport.emit({ data: this.sheetItems() });
  }
}
