import { produce } from 'immer';
import { catchError, concatMap, EMPTY, filter, from, mergeMap, scan, switchMap, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BASE_REQUEST_META_DEFAULT,
  BASE_RESPONSE_META_DEFAULT,
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  fetchInBatches,
  getShiftedDate,
  IQueryResponse,
  Query,
  QueryBuilder,
} from '@supy/common';
import { SnackbarService } from '@supy/components';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';
import { BaseChannel, WebSocketApp, WebSocketClient } from '@supy/websockets';

import { ProviderEnum } from '../../../tenant';
import {
  SalesImport,
  SalesImportProviderSyncStatus,
  SalesImportsRequestProps,
  SalesImportStateEnum,
  SalesImportStats,
  SalesImportSyncStatusResponse,
  SalesTransaction,
  SalesTransactionQueryProps,
  SalesTransactionStateEnum,
} from '../../models';
import { SalesImportService } from '../../services';
import { downloadTransactionsReport } from '../../utils';
import {
  SalesImportCancelTransactions,
  SalesImportDelete,
  SalesImportDeleteTransactions,
  SalesImportDisposeProviderSyncStatusListener,
  SalesImportExportTransactions,
  SalesImportGet,
  SalesImportGetMany,
  SalesImportGetSyncAvailableDates,
  SalesImportGetTransactions,
  SalesImportIgnoreTransactions,
  SalesImportInitFilters,
  SalesImportManualSync,
  SalesImportMapTransactions,
  SalesImportPatchFilters,
  SalesImportPatchRequestMetadata,
  SalesImportPatchTransactionFilters,
  SalesImportPatchTransactionsRequestMetadata,
  SalesImportProviderSync,
  SalesImportResetFilters,
  SalesImportResetProviderSyncStatus,
  SalesImportResetTransactionFilters,
  SalesImportResetTransactions,
  SalesImportSetProviderSyncStatusListener,
  SalesImportSubmit,
  SalesImportToggleIgnoredTransactions,
  SalesImportUnignoreTransactions,
  SalesImportUpdateRecipeTransactions,
  SalesImportUploadSheet,
} from '../actions';

export const POS_SALES_IMPORT_STATE_TOKEN = new StateToken<SalesImportStateModel>('SalesImport');

export interface SalesImportStateModel extends EntityListStateModel<SalesImport> {
  readonly filters: SalesImportFilters;
  readonly requestMetadata: SalesImportRequestMetadata;
  readonly responseMetadata: SalesImportResponseMetadata;
  readonly isProviderSyncInProgress: boolean;
  readonly providerSyncStatus: SalesImportProviderSyncStatus | null;
  readonly transactions: SalesTransaction[];
  readonly transactionsRequestMetadata: BaseRequestMetadata;
  readonly transactionsResponseMetadata: BaseResponseMetadata;
  readonly syncAvailableDates: Date[];
}

export interface SalesImportFilters {
  readonly description: string | null;
  readonly branch: string | null;
  readonly provider: ProviderEnum | null;
  readonly startDate: Date | number | string | null;
  readonly endDate: Date | number | string | null;
  readonly transactionsName: string | null;
  readonly state: SalesImportStateEnum | null;
  readonly transactionsStatus: SalesTransactionStateEnum;
  readonly isIgnoredTransactionsDisplayed: boolean;
}

export interface SalesImportRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
}

export interface SalesImportResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const DEFAULT_TRANSACTIONS_STATUS = SalesTransactionStateEnum.Unmapped;

const FILTERS_DEFAULT = {
  description: null,
  branch: null,
  startDate: null,
  endDate: null,
  provider: null,
  state: null,
  transactionsName: null,
  transactionsStatus: DEFAULT_TRANSACTIONS_STATUS,
  isIgnoredTransactionsDisplayed: false,
};

const EXPORT_TRANSACTIONS_BATCH_SIZE = 500;
const REQUEST_METADATA_DEFAULT = { page: 0, limit: 30, retailerId: null };
const RESPONSE_METADATA_DEFAULT = { total: 0, count: 0 };

@State<SalesImportStateModel>({
  name: POS_SALES_IMPORT_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_METADATA_DEFAULT,
    responseMetadata: RESPONSE_METADATA_DEFAULT,
    isProviderSyncInProgress: false,
    providerSyncStatus: null,
    transactions: [],
    syncAvailableDates: [],
    transactionsRequestMetadata: BASE_REQUEST_META_DEFAULT,
    transactionsResponseMetadata: BASE_RESPONSE_META_DEFAULT,
  },
})
@Injectable({ providedIn: 'root' })
export class SalesImportState extends EntityListState {
  private readonly salesImportService = inject(SalesImportService);
  private readonly snackbarService = inject(SnackbarService);
  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  private providerSyncStatusChannel: BaseChannel;

  constructor(@Inject(WebSocketApp.Default) private readonly websocketClient: WebSocketClient) {
    super();
  }

  @Selector()
  static filters(state: SalesImportStateModel) {
    return EntityListState.currentFilters<SalesImportFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: SalesImportStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static requestMetadata(state: SalesImportStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: SalesImportStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static salesImports(state: SalesImportStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentSalesImport(state: SalesImportStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static providerSyncStatus(state: SalesImportStateModel) {
    return state.providerSyncStatus;
  }

  @Selector()
  static isProviderSyncInProgress(state: SalesImportStateModel) {
    return state.isProviderSyncInProgress;
  }

  @Selector()
  static syncAvailableDates(state: SalesImportStateModel) {
    return state.syncAvailableDates;
  }

  @Selector()
  static transactions(state: SalesImportStateModel) {
    return state.transactions;
  }

  @Selector()
  static transactionsAwaitToBePatched(state: SalesImportStateModel) {
    return state.transactions.filter(({ linkedRecipe }) => linkedRecipe?.isLocallyPatched);
  }

  @Selector()
  static transactionsStatus(state: SalesImportStateModel) {
    return state.filters.transactionsStatus;
  }

  @Selector()
  static transactionsResponseMetadata(state: SalesImportStateModel) {
    return state.transactionsResponseMetadata;
  }

  @Selector()
  static transactionsRequestMetadata(state: SalesImportStateModel) {
    return state.transactionsRequestMetadata;
  }

  @Selector()
  static isIgnoredTransactionsDisplayed(state: SalesImportStateModel) {
    return state.filters.isIgnoredTransactionsDisplayed;
  }

  @Selector()
  static uniqueIgnoredItemsCount(state: SalesImportStateModel) {
    return state.transactions.filter(trx => trx.state === SalesTransactionStateEnum.Ignored && trx.quantity > 0)
      ?.length;
  }

  @Action(SalesImportInitFilters)
  initSalesImportFilter(ctx: StateContext<SalesImportStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(SalesImportPatchFilters)
  patchSalesImportFilter(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportPatchFilters) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);
    ctx.dispatch([new SalesImportPatchRequestMetadata({ page: 0 }), new SalesImportGetMany()]);
  }

  @Action(SalesImportResetFilters)
  resetSalesImportsFilter(ctx: StateContext<SalesImportStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new SalesImportPatchRequestMetadata({ page: 0 }), new SalesImportGetMany()]);
  }

  @Action(SalesImportPatchTransactionFilters)
  patchSalesTransactionsFilter(
    ctx: StateContext<SalesImportStateModel>,
    { payload, fetchAfter }: SalesImportPatchTransactionFilters,
  ) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);
    ctx.dispatch(new SalesImportPatchTransactionsRequestMetadata({ page: 0 }));

    if (fetchAfter) {
      ctx.dispatch(new SalesImportGetTransactions());
    }
  }

  @Action(SalesImportResetTransactionFilters)
  resetSalesTransactionsFilter(ctx: StateContext<SalesImportStateModel>) {
    super.resetFilter(ctx, {
      ...ctx.getState().filters,
      transactionsName: FILTERS_DEFAULT.transactionsName,
      transactionsStatus: FILTERS_DEFAULT.transactionsStatus,
    });

    ctx.dispatch(new SalesImportPatchTransactionsRequestMetadata({ page: 0 }));
  }

  @Action(SalesImportPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(SalesImportGetSyncAvailableDates, { cancelUncompleted: true })
  getSyncAvailableDates(
    ctx: StateContext<SalesImportStateModel>,
    { retailerId, tenantId, branchId, provider }: SalesImportGetSyncAvailableDates,
  ) {
    return this.salesImportService
      .getSyncAvailableDates(retailerId, tenantId, branchId, provider)
      .pipe(tap(({ dates }) => ctx.patchState({ syncAvailableDates: dates })));
  }

  @Action(SalesImportGetMany, { cancelUncompleted: true })
  getSalesImports(ctx: StateContext<SalesImportStateModel>) {
    return this.salesImportService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(SalesImportGet)
  getSalesImport(ctx: StateContext<SalesImportStateModel>, action: SalesImportGet) {
    return this.getOne<SalesImport, SalesImportStateModel>(ctx, {
      ...action.payload,
      fetchApi: () => this.salesImportService.get(action.payload.id),
    }).pipe(
      tap(salesImport => {
        if (
          SalesImportState.isIgnoredTransactionsDisplayed(ctx.getState()) &&
          !salesImport.statistics.totalIgnoredItems
        ) {
          ctx.dispatch(new SalesImportToggleIgnoredTransactions());
        }
      }),
      mergeMap(salesImport =>
        SalesImportState.transactionsStatus(ctx.getState()) !== SalesTransactionStateEnum.Unmapped
          ? EMPTY
          : ctx.dispatch(
              new SalesImportPatchTransactionFilters(
                {
                  transactionsStatus: this.getDefaultTransactionsStatus(salesImport.statistics),
                },
                action.fetchTransactions,
              ),
            ),
      ),
    );
  }

  private getDefaultTransactionsStatus(stats: SalesImportStats): SalesTransactionStateEnum {
    const statuses = {
      [SalesTransactionStateEnum.Unmapped]: stats.totalUnmappedItems,
      [SalesTransactionStateEnum.Unlinked]: stats.totalUnlinkedItems,
      [SalesTransactionStateEnum.Canceled]: stats.totalCanceledItems,
      [SalesTransactionStateEnum.Synced]: stats.totalSyncedItems,
    };

    return (
      (Object.entries(statuses)
        .find(([status, value]) => value > 0)
        ?.at(0) as SalesTransactionStateEnum) ?? DEFAULT_TRANSACTIONS_STATUS
    );
  }

  @Action(SalesImportSubmit)
  submitSalesImport(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportSubmit) {
    return this.salesImportService.submit(payload.id);
  }

  @Action(SalesImportUploadSheet)
  uploadSalesImportSheet(
    ctx: StateContext<SalesImportStateModel>,
    { uploadPayload, createPayload }: SalesImportUploadSheet,
  ) {
    return this.salesImportService
      .uploadSheet(uploadPayload)
      .pipe(switchMap(({ fileUrl }) => ctx.dispatch(new SalesImportManualSync({ ...createPayload, fileUrl }))));
  }

  @Action(SalesImportManualSync)
  manualSyncSalesImport(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportManualSync) {
    return this.salesImportService.manualSync(payload).pipe(
      tap(salesImport => {
        super.setOne(ctx, salesImport);
        super.setSequence(ctx, { id: salesImport.id, isFirst: true, isLast: true });
      }),
    );
  }

  @Action(SalesImportDelete)
  deleteSalesImport(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportDelete) {
    return this.salesImportService.delete(payload.id);
  }

  @Action(SalesImportDeleteTransactions)
  deleteSalesTransactions(ctx: StateContext<SalesImportStateModel>, { importId, body }: SalesImportDeleteTransactions) {
    return this.salesImportService.deleteTransactions(importId, body).pipe(
      mergeMap(() => ctx.dispatch(new SalesImportGet({ id: importId }))),
      tap(() =>
        ctx.patchState({
          transactions: ctx.getState().transactions.filter(({ id }) => !body.transactionIds.includes(id)),
        }),
      ),
    );
  }

  @Action(SalesImportMapTransactions)
  mapTransactions(ctx: StateContext<SalesImportStateModel>, { retailerId, providerBrand }: SalesImportMapTransactions) {
    const patchedTransactions = SalesImportState.transactionsAwaitToBePatched(ctx.getState());

    if (!patchedTransactions.length) {
      return EMPTY;
    }

    return this.salesImportService
      .mapTransactions({
        retailerId,
        providerBrand,
        data: patchedTransactions.map(({ posItem, linkedRecipe }) => ({
          posItemCode: posItem.code,
          recipeId: linkedRecipe?.id as string,
        })),
      })
      .pipe(
        mergeMap(() =>
          ctx.dispatch(new SalesImportGet({ id: SalesImportState.current(ctx.getState())?.id as string })),
        ),
      );
  }

  @Action(SalesImportCancelTransactions)
  cancelTransactions(ctx: StateContext<SalesImportStateModel>, { importId }: SalesImportCancelTransactions) {
    return this.salesImportService.cancelTransactions(importId).pipe(
      tap({
        next: () => ctx.dispatch([new SalesImportGet({ id: importId }), new SalesImportGetTransactions()]),
        error: () => ctx.dispatch([new SalesImportGet({ id: importId }), new SalesImportGetTransactions()]),
      }),
    );
  }

  @Action(SalesImportIgnoreTransactions)
  ignoreTransactions(
    ctx: StateContext<SalesImportStateModel>,
    { importId, requestBody }: SalesImportIgnoreTransactions,
  ) {
    return this.salesImportService
      .ignoreTransactions(requestBody)
      .pipe(
        mergeMap(() => [
          ctx.dispatch(new SalesImportGet({ id: importId })),
          ctx.dispatch(new SalesImportGetTransactions()),
        ]),
      );
  }

  @Action(SalesImportUnignoreTransactions)
  unignoreTransactions(
    ctx: StateContext<SalesImportStateModel>,
    { importId, requestBody }: SalesImportUnignoreTransactions,
  ) {
    return this.salesImportService.unignoreTransactions(requestBody).pipe(
      mergeMap(() => ctx.dispatch([new SalesImportGet({ id: importId }), new SalesImportGetTransactions()])),
      mergeMap(() =>
        ctx.getState().transactions?.some(({ state }) => state === SalesTransactionStateEnum.Ignored)
          ? EMPTY
          : ctx.dispatch(new SalesImportToggleIgnoredTransactions()),
      ),
    );
  }

  @Action(SalesImportUpdateRecipeTransactions)
  updateRecipeTransactions(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportUpdateRecipeTransactions) {
    ctx.patchState({
      transactions: ctx.getState().transactions.map(trx => ({
        ...trx,
        linkedRecipe:
          payload.recipe && trx.posItem.code === payload.posItemCode
            ? { id: payload.recipe.id, name: payload.recipe.name, isLocallyPatched: true }
            : !payload.recipe && trx.posItem.code === payload.posItemCode && trx.linkedRecipe?.isLocallyPatched
              ? null
              : trx.linkedRecipe,
      })),
    });
  }

  @Action(SalesImportPatchTransactionsRequestMetadata)
  patchTransactionsRequestMetadata(
    ctx: StateContext<SalesImportStateModel>,
    { payload }: SalesImportPatchTransactionsRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.transactionsRequestMetadata = {
          ...ctx.getState().transactionsRequestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(SalesImportResetTransactions)
  resetTransactions(ctx: StateContext<SalesImportStateModel>) {
    ctx.patchState({
      transactions: [],
      transactionsRequestMetadata: BASE_REQUEST_META_DEFAULT,
      transactionsResponseMetadata: BASE_RESPONSE_META_DEFAULT,
    });
  }

  @Action(SalesImportToggleIgnoredTransactions)
  toggleIgnoredTransactions(ctx: StateContext<SalesImportStateModel>) {
    const isIgnoredActive = ctx.getState().filters.isIgnoredTransactionsDisplayed;

    return ctx.dispatch(
      new SalesImportPatchTransactionFilters({
        isIgnoredTransactionsDisplayed: !isIgnoredActive,
        transactionsStatus: !isIgnoredActive
          ? SalesTransactionStateEnum.Ignored
          : this.getDefaultTransactionsStatus(SalesImportState.current(ctx.getState())?.statistics as SalesImportStats),
      }),
    );
  }

  @Action(SalesImportProviderSync)
  providerSyncSalesData(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportProviderSync) {
    ctx.patchState({ isProviderSyncInProgress: true });

    return this.salesImportService.providerSync(payload).pipe(
      catchError((error: HttpErrorResponse) => {
        ctx.patchState({ isProviderSyncInProgress: false, providerSyncStatus: 'error' });

        return throwError(() => error);
      }),
    );
  }

  @Action(SalesImportResetProviderSyncStatus)
  resetProviderSyncStatus(ctx: StateContext<SalesImportStateModel>) {
    ctx.patchState({ providerSyncStatus: null, isProviderSyncInProgress: false });
  }

  @Action(SalesImportSetProviderSyncStatusListener)
  setProviderSyncStatusListener(
    ctx: StateContext<SalesImportStateModel>,
    { branchId, tenantId }: SalesImportSetProviderSyncStatusListener,
  ) {
    const channelName = 'sales-import-details-sync';
    const eventName = `${channelName}.status-updated`;

    this.providerSyncStatusChannel = this.websocketClient.subscribe(`private-${channelName}-${tenantId}-${branchId}`);

    this.providerSyncStatusChannel.on(eventName, (resp: SalesImportSyncStatusResponse) =>
      this.providerSyncStatusListenerCb(ctx)(resp),
    );
  }

  private providerSyncStatusListenerCb(ctx: StateContext<SalesImportStateModel>) {
    return ({ message, status, isCompleted }: SalesImportSyncStatusResponse) => {
      const syncInProgress = !['not-started', 'done', 'error'].includes(status);

      if (!syncInProgress) {
        ctx.dispatch(new SalesImportDisposeProviderSyncStatusListener());
      }

      if (isCompleted) {
        ctx.dispatch(new Navigate(['/integrations', 'sales-imports']));
      }

      if (message) {
        this.snackbarService.open(message, { variant: status === 'error' ? 'error' : 'success' });
      }

      ctx.patchState({ providerSyncStatus: status, isProviderSyncInProgress: syncInProgress });
    };
  }

  @Action(SalesImportDisposeProviderSyncStatusListener)
  disposeProviderSyncStatusListener(ctx: StateContext<SalesImportStateModel>) {
    this.providerSyncStatusChannel?.dispose();
  }

  @Action(SalesImportGetTransactions, { cancelUncompleted: true })
  getTransactions(ctx: StateContext<SalesImportStateModel>) {
    const state = ctx.getState();

    return this.salesImportService
      .getTransactions(
        SalesImportState.current(state)?.id as string,
        this.getTransactionsQuery(state, SalesImportState.transactionsStatus(state)),
      )
      .pipe(
        tap(({ data, metadata }) =>
          ctx.patchState({
            transactions: data,
            transactionsResponseMetadata: metadata,
          }),
        ),
      );
  }

  @Action(SalesImportExportTransactions)
  exportTransactions(ctx: StateContext<SalesImportStateModel>, { payload }: SalesImportExportTransactions) {
    const currentImport = SalesImportState.currentSalesImport(ctx.getState()) as SalesImport;
    const {
      totalIgnoredItems,
      totalUnmappedItems,
      totalUnlinkedItems,
      totalCanceledItems,
      totalSyncedItems,
      totalProcessedItems,
    } = currentImport.statistics;

    const statesToFetch = Object.values(SalesTransactionStateEnum).filter(
      state =>
        (state === SalesTransactionStateEnum.Ignored && totalIgnoredItems) ||
        (state === SalesTransactionStateEnum.Unmapped && totalUnmappedItems) ||
        (state === SalesTransactionStateEnum.Unlinked && totalUnlinkedItems) ||
        (state === SalesTransactionStateEnum.Canceled && totalCanceledItems) ||
        (state === SalesTransactionStateEnum.Synced && totalSyncedItems) ||
        (state === SalesTransactionStateEnum.Processed && totalProcessedItems),
    );

    return from(statesToFetch).pipe(
      concatMap(transactionState =>
        fetchInBatches<SalesTransaction, SalesTransactionQueryProps>(
          this.salesImportService.getTransactions.bind(this.salesImportService, currentImport.id),
          this.getTransactionsQuery(ctx.getState(), transactionState, false),
          EXPORT_TRANSACTIONS_BATCH_SIZE,
        ),
      ),
      scan(
        (group: IQueryResponse<SalesTransaction>, transactions: IQueryResponse<SalesTransaction>) => ({
          data: [...group.data, ...transactions.data],
          metadata: {
            count: group.metadata.count + transactions.metadata.count,
            total: group.metadata.total + transactions.metadata.total,
          },
        }),
        { data: [], metadata: { count: 0, total: 0 } } as IQueryResponse<SalesTransaction>,
      ),
      filter(transactions => !!transactions.data?.find(transaction => transaction.state === [...statesToFetch].pop())),
      tap(({ data }) => void downloadTransactionsReport(data, payload.branchName, this.#hidePrices())),
    );
  }

  private getQuery(state: SalesImportStateModel) {
    const { description, state: status, provider, startDate, endDate, branch } = state.filters;

    const qb = new QueryBuilder<SalesImport & SalesImportsRequestProps>({
      filtering: [],
      paging: {
        offset: state.requestMetadata.page * state.requestMetadata.limit,
        limit: state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        {
          by: 'id',
          dir: 'desc',
        },
      ],
    });

    if (description) {
      qb.filtering.setFilter({
        by: 'description',
        match: description,
        op: 'like',
      });
    }

    if (provider) {
      qb.filtering.setFilter({
        by: 'providerBrand',
        match: provider,
        op: 'eq',
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'state',
        match: status,
        op: 'eq',
      });
    }

    if (startDate && endDate) {
      qb.filtering.withFiltering([
        { by: 'startDate', op: 'lte', match: getShiftedDate(new Date(endDate), this.#utcOffset()).getTime() },
        { by: 'endDate', op: 'gte', match: getShiftedDate(new Date(startDate), this.#utcOffset()).getTime() },
      ]);
    }

    if (branch) {
      qb.filtering.setFilter({
        by: 'branchId',
        match: branch,
        op: 'eq',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    return qb.build();
  }

  private getTransactionsQuery(
    state: SalesImportStateModel,
    transactionsStatus: SalesTransactionStateEnum,
    isSyncedCombinedProcessed = true,
  ): Query<SalesTransactionQueryProps> {
    const qb = new QueryBuilder<SalesTransactionQueryProps>({
      paging: {
        offset: state.transactionsRequestMetadata.page * state.transactionsRequestMetadata.limit,
        limit: state.transactionsRequestMetadata.limit,
      },
      filtering: [
        {
          by: 'state',
          match:
            transactionsStatus === SalesTransactionStateEnum.Synced && isSyncedCombinedProcessed
              ? [transactionsStatus, SalesTransactionStateEnum.Processed]
              : [transactionsStatus],
          op: 'in',
        },
        {
          by: 'retailerId',
          match: state.requestMetadata.retailerId,
          op: 'eq',
        },
      ],
    });

    if (state.filters?.transactionsName) {
      qb.filtering.setFilter(
        {
          by: 'term',
          match: state.filters.transactionsName,
          op: 'like',
        },
        false,
      );
    }

    return qb.build();
  }
}
