import { filter, Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  input,
  output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GridPagingMode, GridSelectionMode, IRowSelectionEventArgs } from '@infragistics/igniteui-angular';

import { BaseRequestMetadata, BaseResponseMetadata, DEFAULT_QUANTITY_PRECISION } from '@supy/common';
import { AutocompleteComponent, GridComponent, IconType, TabViewSelectItem } from '@supy/components';
import {
  AutocompleteFinishedRecipe,
  AutocompleteFinishedRecipesQueryProps,
  RecipePermissionStrategy,
} from '@supy/inventory';
import { getLocalizedName } from '@supy/settings';

import { PosIntegrationPermissionsStrategy } from '../../../pos-item';
import {
  SalesImport,
  SalesImportStateEnum,
  SalesImportStats,
  SalesTransaction,
  SalesTransactionStateEnum,
  UpdateRecipeTransactionsPayload,
} from '../../models';

type SalesTransactionGridEntry = SalesTransaction & { idToOrderId: string };

@Component({
  selector: 'supy-sales-transactions',
  templateUrl: './sales-transactions.component.html',
  styleUrls: ['./sales-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesTransactionsComponent {
  @ViewChild(GridComponent, { static: true })
  protected readonly grid: GridComponent;

  @ViewChildren(AutocompleteComponent, { read: AutocompleteComponent })
  readonly autocompletes: QueryList<AutocompleteComponent<{ id: string }>>;

  @Input() set transactions(value: SalesTransaction[]) {
    if (value) {
      this.resetSelectedRows();
      this.gridTransactions.set(
        value.map(transaction => ({
          ...transaction,
          idToOrderId: transaction.providerOrderId
            ? `${transaction.id}:${transaction.providerOrderId}`
            : transaction.id,
        })),
      );
    }
  }

  @Input() set salesImport(value: SalesImport) {
    if (value) {
      this.salesImportValue.set(value);
      this.statuses.set(this.getStatuses(value.statistics));
    }
  }

  @Input() readonly isAutoProcessed: boolean;
  @Input() readonly isSalesTypeBlocked: boolean;
  @Input() readonly status: SalesTransactionStateEnum;
  @Input() readonly retailerId: string;
  @Input() readonly awaitToBePatched: boolean;
  @Input() readonly requestMetadata: BaseRequestMetadata;
  @Input() readonly responseMetadata: BaseResponseMetadata;
  @Input() readonly currencyPrecision: number;
  @Input() readonly minimumAllowedDate: Date;
  @Input() readonly salesLimitMonths: number;
  @Input() readonly preventBackdatedUpdates: boolean;
  @Input() set recipes(value: AutocompleteFinishedRecipe[]) {
    this.recipesList.set(value);
  }

  @Input() readonly areRecipesLoading: boolean;
  @Input() readonly areRecipesLoaded: Observable<boolean>;
  @Input() readonly isExportAllowed: boolean;
  @Input() readonly isLoading: boolean;
  @Input() set isIgnoredDisplayed(value: boolean) {
    this.isIgnoredDisplayedValue.set(value);
    this.statuses.set(this.getStatuses(this.salesImportValue()?.statistics));
  }

  readonly hidePrices = input<boolean>();

  readonly action = output<void>();
  readonly export = output<void>();
  readonly search = output<string>();
  readonly ignore = output<SalesTransaction>();
  readonly unignore = output<SalesTransaction>();
  readonly transactionsDeleted = output<string[]>();
  readonly recipesSearchValueChange = output<AutocompleteFinishedRecipesQueryProps>();
  readonly pageChange = output<{ page: number; isPendingAction: boolean }>();
  readonly statusChange = output<{
    status: SalesTransactionStateEnum;
    isPendingAction: boolean;
  }>();

  readonly recipeUpdated = output<UpdateRecipeTransactionsPayload>();

  protected readonly gridTransactions = signal<SalesTransactionGridEntry[]>([]);
  protected readonly recipesList = signal<AutocompleteFinishedRecipe[]>([]);
  protected readonly currentRecipeId = signal<string | null>(null);
  protected readonly statuses = signal(this.getStatuses());
  protected readonly isIgnoredDisplayedValue = signal(false);
  private readonly salesImportValue = signal<SalesImport | null>(null);

  readonly rowHeight = 35;

  readonly salesTransactionState = SalesTransactionStateEnum;

  protected readonly gridSelectionMode = GridSelectionMode;
  protected readonly paginationMode = GridPagingMode.Remote;
  private readonly selectedRows = new Set<string>();

  protected readonly canUpdatePosMappingItem = toSignal(
    inject(PosIntegrationPermissionsStrategy.UpdateMappingItem).isAllowed(),
  );

  protected readonly canDeleteSalesImport = toSignal(
    inject(PosIntegrationPermissionsStrategy.DeleteImport).isAllowed(),
  );

  protected readonly canCreateRecipe = toSignal(inject(RecipePermissionStrategy.Create).isAllowed());

  get gridSelectedRows(): string[] {
    return Array.from(this.selectedRows);
  }

  get gridHeight() {
    return document.getElementsByClassName('igx-grid__tbody')[0].clientHeight;
  }

  get isOrderShown(): boolean {
    return this.status === SalesTransactionStateEnum.Canceled;
  }

  get isSalesTypeShown(): boolean {
    return this.isSalesTypeBlocked === false;
  }

  get isRecipeShown(): boolean {
    return this.status !== SalesTransactionStateEnum.Ignored;
  }

  get actionTitle(): string | null {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return this.isPendingAction() ? 'Map Recipes' : null;
      case SalesTransactionStateEnum.Canceled:
        return this.isPossibleToWastageConvert() ? 'Convert All to Wastage' : null;
      case SalesTransactionStateEnum.Synced:
        return this.isPossibleToSubmit() ? 'Process' : null;
      default:
        return null;
    }
  }

  get bannerMessage(): string | null {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return $localize`:@@integrations.unmappedWarning:The below transactions have no recipe or sales type`;
      case SalesTransactionStateEnum.Unlinked:
        return $localize`:@@integrations.unlinkedWarning:The below recipes lack sales type, cost center or need to be backdated`;
      case SalesTransactionStateEnum.Canceled:
        return this.preventBackdatedUpdates
          ? $localize`:@@integrations.salesLimitCaptionBackdated:Actions have been disabled on events before the latest stock count date for this branch, or older than ${this.salesLimitMonths}:INTERPOLATION: months.`
          : $localize`:@@integrations.salesLimitCaption:Actions have been disabled on events before the initial stock count date for this branch, or older than ${this.salesLimitMonths}:INTERPOLATION: months.`;
      default:
        return null;
    }
  }

  protected isPendingAction(): boolean {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return this.awaitToBePatched;
      default:
        return false;
    }
  }

  protected isRecipeSelectionShown(trx: SalesTransaction): boolean {
    return !!(
      this.canUpdatePosMappingItem() &&
      this.status === SalesTransactionStateEnum.Unmapped &&
      (trx?.linkedRecipe ? trx?.linkedRecipe?.isLocallyPatched : true)
    );
  }

  protected onRecipeSelected(recipe: AutocompleteFinishedRecipe, posItemCode: string): void {
    this.recipeUpdated.emit({ recipe, posItemCode });
  }

  protected onRecipeCleared(id: string, posItemCode: string): void {
    for (const trx of this.gridTransactions()) {
      const isSameCode = posItemCode === trx.posItem.code;

      if (isSameCode && id !== trx.id) {
        this.resetAutocomplete(trx.id);
      }
    }

    this.recipeUpdated.emit({ posItemCode });
  }

  protected getSelectedRows(): string[] {
    return this.grid.getSelectedRows().map(idToOrderId => idToOrderId.split(':')[0]);
  }

  protected resetRecipes(): void {
    this.recipesList.set([]);
  }

  protected resetSelectedRows(): void {
    return this.selectedRows.clear();
  }

  protected onSelectionChange(selectionArgs: IRowSelectionEventArgs): void {
    const newSelection = selectionArgs.newSelection as SalesTransactionGridEntry[];

    if (selectionArgs.allRowsSelected) {
      newSelection.forEach(({ idToOrderId }) => this.selectedRows.add(idToOrderId));

      return;
    }

    if (!newSelection?.length) {
      this.selectedRows.clear();
    }

    const addedSelection = selectionArgs.added[0] as SalesTransactionGridEntry;
    const removedSelection = selectionArgs.removed[0] as SalesTransactionGridEntry;

    if (addedSelection) {
      [...newSelection, ...this.getChildrenTransactions(addedSelection.id)].forEach(({ idToOrderId }) =>
        this.selectedRows.add(idToOrderId),
      );
    }

    if (removedSelection) {
      const childrenTransactions = this.getChildrenTransactions(removedSelection.id);
      const rowsToBeFilteredOut = childrenTransactions?.length
        ? [removedSelection.idToOrderId, ...(childrenTransactions?.map(({ idToOrderId }) => idToOrderId) ?? [])]
        : [removedSelection.idToOrderId];

      rowsToBeFilteredOut.forEach(idToOrderId => this.selectedRows.delete(idToOrderId));
    }
  }

  protected deleteTransactions(): void {
    this.transactionsDeleted.emit(this.grid?.getSelectedRows().map(idToOrderId => idToOrderId.split(':')[0]));
  }

  protected deleteTransaction(transactionId: string): void {
    this.transactionsDeleted.emit([transactionId]);
  }

  protected recipeDisplayValueFn(value: AutocompleteFinishedRecipe): string {
    return getLocalizedName(value?.name);
  }

  protected getRecipeIcon(rowData: SalesTransaction): IconType | null {
    return rowData.linkedRecipe ? 'food' : null;
  }

  protected onIgnore(salesTransaction: SalesTransaction): void {
    this.ignore.emit(salesTransaction);
  }

  protected onUnignore(salesTransaction: SalesTransaction): void {
    this.unignore.emit(salesTransaction);
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit({ page, isPendingAction: this.isPendingAction() });
  }

  protected onStatusChange(status: SalesTransactionStateEnum): void {
    this.statusChange.emit({ status, isPendingAction: this.isPendingAction() });
  }

  protected onRecipesSearchValueChange(term: string, rowData: SalesTransaction): void {
    this.currentRecipeId.set(rowData.id);
    this.recipesSearchValueChange.emit({
      retailerId: this.retailerId,
      term,
      effectiveDate: new Date(rowData.businessDay).toISOString(),
    });
  }

  protected getRecipesFetchDone(rowData: SalesTransaction): Observable<boolean> {
    return this.areRecipesLoaded.pipe(filter(() => this.currentRecipeId() === rowData.id));
  }

  protected isDateLimitValid(date: Date): boolean {
    return new Date(date).getTime() > new Date(this.minimumAllowedDate).getTime();
  }

  private getChildrenTransactions(transactionId: string): SalesTransactionGridEntry[] {
    return this.gridTransactions().filter(({ parentTransactionId }) => parentTransactionId === transactionId) ?? [];
  }

  private isPossibleToWastageConvert(): boolean {
    if (!this.salesImportValue()) {
      return false;
    }

    const { statistics } = this.salesImportValue() as SalesImport;

    return (statistics?.totalCanceledItems ?? 0) > 0;
  }

  private isPossibleToSubmit(): boolean {
    if (!this.salesImportValue()) {
      return false;
    }

    const { statistics, metadata, state } = this.salesImportValue() as SalesImport;

    return (
      !metadata?.autoProcessed &&
      state !== SalesImportStateEnum.Processed &&
      statistics?.totalUnmappedItems === 0 &&
      statistics?.totalUnlinkedItems === 0 &&
      statistics?.totalCanceledItems === 0 &&
      (statistics?.totalSyncedItems ?? 0) > 0
    );
  }

  protected isPossibleToIgnore(transaction: SalesTransaction): boolean {
    return transaction.state === SalesTransactionStateEnum.Unmapped;
  }

  protected isPossibleToUnignore(transaction: SalesTransaction): boolean {
    return transaction.state === SalesTransactionStateEnum.Ignored;
  }

  private getStatuses(stats?: SalesImportStats): TabViewSelectItem<SalesTransactionStateEnum>[] {
    const statuses = [
      {
        label: $localize`:@@integrations.unmapped:Unmapped`,
        value: SalesTransactionStateEnum.Unmapped,
        icon: 'unlink' as IconType,
        suffix: `${stats?.totalUnmappedItems || ''}`,
      },
      {
        label: $localize`:@@integrations.unlinked:Unlinked`,
        value: SalesTransactionStateEnum.Unlinked,
        icon: 'unlink-2' as IconType,
        suffix: `${stats?.totalUnlinkedItems || ''}`,
      },
      {
        label: $localize`:@@integrations.cancelled:Void/Canceled`,
        value: SalesTransactionStateEnum.Canceled,
        icon: 'undo' as IconType,
        suffix: `${stats?.totalCanceledItems || ''}`,
      },
      {
        label: $localize`:@@integrations.synced:Synced`,
        value: SalesTransactionStateEnum.Synced,
        icon: 'check-circle' as IconType,
        suffix: `${stats?.totalSyncedItems || ''}`,
      },
    ];

    if (this.isIgnoredDisplayedValue?.()) {
      statuses.unshift({
        label: $localize`:@@integrations.ignored:Ignored`,
        value: SalesTransactionStateEnum.Ignored,
        icon: 'eye-slash' as IconType,
        suffix: `${stats?.totalIgnoredItems || ''}`,
      });
    }

    return statuses;
  }

  resetAutocompletes(): void {
    this.autocompletes.forEach(autocomplete => autocomplete.onClear());
  }

  resetAutocomplete(id: string): void {
    this.autocompletes?.find(autocomplete => autocomplete.id === id)?.onClear(id, false);
  }

  protected readonly getLocalizedName = getLocalizedName;
  protected readonly DEFAULT_QUANTITY_PRECISION = DEFAULT_QUANTITY_PRECISION;
}
