import { inject, Injectable } from '@angular/core';

import { IntegrationsConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { ProviderEnum } from '../tenant';
import {
  LIGHT_SPEED_RESOURCE_TYPE,
  LIGHT_SPEED_SCOPE,
  LOYVERSE_SCOPE,
  MYOB_RESOURCE_TYPE,
  MYOB_SCOPE,
  ORACLE_NETSUITE_RESOURCE_TYPE,
  ORACLE_NETSUITE_SCOPE,
  POSTER_RESPONSE_TYPE,
  PROVIDER_AUTH_STATE_KEY,
  QUICKBOOKS_RESOURCE_TYPE,
  QUICKBOOKS_SCOPE,
  QUICKBOOKS_STATE,
  SHOPIFY_SCOPE,
  SQUARE_SCOPE,
  WAFEQ_RESPONSE_TYPE,
  WAFEQ_SCOPE,
  XERO_RESOURCE_TYPE,
  XERO_SCOPE,
  ZID_RESPONSE_TYPE,
  ZOHO_ACCESS_TYPE_SCOPE,
  ZOHO_PROMPT,
  ZOHO_RESOURCE_TYPE,
  ZOHO_SCOPE,
} from './oauth2-provider-scopes';

export const OAUTH2_HOST_SESSION_KEY = 'provider-oauth2-host';

@Injectable({
  providedIn: 'root',
})
export class IntegrationProviderOAuth2Service {
  private readonly integrationsConfig = inject<IntegrationsConfig>(APP_CONFIG);
  private readonly urlMap: { [provider: string]: (...args: string[]) => URL } = {
    [ProviderEnum.Xero]: () => this.getXeroAuthUrl(),
    [ProviderEnum.OracleNetsuite]: () => this.getNetsuiteAuthUrl(),
    [ProviderEnum.Foodics]: () => this.getFoodicsAuthUrl(),
    [ProviderEnum.Loyverse]: () => this.getLoyverseAuthUrl(),
    [ProviderEnum.Zoho]: () => this.getZohoAuthUrl(),
    [ProviderEnum.Myob]: () => this.getMyobAuthUrl(),
    [ProviderEnum.Quickbooks]: () => this.getQuickbooksAuthUrl(),
    [ProviderEnum.LightSpeed]: () => this.getLightspeedAuthUrl(),
    [ProviderEnum.LightSpeedX]: () => this.getLightspeedXAuthUrl(),
    [ProviderEnum.Square]: () => this.getSquareAuthUrl(),
    [ProviderEnum.LightSpeedO]: () => this.getLightspeedOAuthUrl(),
    [ProviderEnum.Poster]: () => this.getPosterOAuthUrl(),
    [ProviderEnum.ZidPos]: () => this.getZidOAuthUrl(),
    [ProviderEnum.Wafeq]: () => this.getWafeqAuthUrl(),
    [ProviderEnum.Shopify]: (...args: string[]) => this.getShopifyAuthUrl(args[0]),
  };

  authenticate(provider: ProviderEnum, ...args: string[]): void {
    const authUrl = this.urlMap[provider](...args);

    if (!authUrl?.href) {
      throw Error(
        $localize`:@@integrations.oauthError:No authorize url provided. Aborting oauth2 integration authentication`,
      );
    }

    location.href = authUrl.href;
  }

  private getXeroAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.xero.apiUrl}/identity/connect/authorize`);

    authorizeUrl.searchParams.set('response_type', XERO_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.xero.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.xero.redirectUri);
    authorizeUrl.searchParams.set('scope', XERO_SCOPE);

    return authorizeUrl;
  }

  private getNetsuiteAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.oracleNetsuite.apiUrl}/oauth2/authorize.nl`);

    authorizeUrl.searchParams.set('scope', ORACLE_NETSUITE_SCOPE);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.oracleNetsuite.redirectUri);
    authorizeUrl.searchParams.set('response_type', ORACLE_NETSUITE_RESOURCE_TYPE);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.oracleNetsuite.clientId);
    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  private getWafeqAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.wafeq.apiUrl}/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.wafeq.clientId);
    authorizeUrl.searchParams.set('response_type', WAFEQ_RESPONSE_TYPE);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.wafeq.redirectUri);
    authorizeUrl.searchParams.set('scope', WAFEQ_SCOPE);

    return authorizeUrl;
  }

  private getFoodicsAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.foodics.apiUrl}/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.foodics.clientId);
    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  private getLoyverseAuthUrl(): URL {
    const authorizeUrl = `${this.integrationsConfig.loyverse.apiUrl}/oauth/authorize?client_id=${
      this.integrationsConfig.loyverse.clientId
    }&scope=${encodeURIComponent(LOYVERSE_SCOPE)}&redirect_uri=${
      this.integrationsConfig.loyverse.redirectUri
    }&state=${this.generateState()}&response_type=code`;

    return new URL(authorizeUrl);
  }

  private getZohoAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.zoho.apiUrl}`);

    authorizeUrl.searchParams.set('response_type', ZOHO_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.zoho.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.zoho.redirectUri);
    authorizeUrl.searchParams.set('scope', ZOHO_SCOPE);
    authorizeUrl.searchParams.set('access_type', ZOHO_ACCESS_TYPE_SCOPE);
    authorizeUrl.searchParams.set('prompt', ZOHO_PROMPT);

    return authorizeUrl;
  }

  private getMyobAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.myob.apiUrl}`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.myob.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.myob.redirectUri);
    authorizeUrl.searchParams.set('response_type', MYOB_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('scope', MYOB_SCOPE);

    return authorizeUrl;
  }

  private getQuickbooksAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.quickbooks.apiUrl}`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.quickbooks.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.quickbooks.redirectUri);
    authorizeUrl.searchParams.set('response_type', QUICKBOOKS_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('scope', QUICKBOOKS_SCOPE);
    authorizeUrl.searchParams.set('state', QUICKBOOKS_STATE);

    return authorizeUrl;
  }

  private getLightspeedAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.lightSpeed.apiUrl}/oauth/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.lightSpeed.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.lightSpeed.redirectUri);
    authorizeUrl.searchParams.set('response_type', LIGHT_SPEED_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('scope', LIGHT_SPEED_SCOPE);
    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  private getLightspeedXAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.lightSpeedX.apiUrl}/oauth/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.lightSpeedX.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.lightSpeedX.redirectUri);
    authorizeUrl.searchParams.set('response_type', LIGHT_SPEED_RESOURCE_TYPE);

    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  private getSquareAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.square.apiUrl}`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.square.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.square.redirectUri);
    authorizeUrl.searchParams.set('scope', SQUARE_SCOPE);

    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  getLightspeedOAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.lightSpeedO.apiUrl}/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.lightSpeedO.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.lightSpeedO.redirectUri);
    authorizeUrl.searchParams.set('response_type', LIGHT_SPEED_RESOURCE_TYPE);
    authorizeUrl.searchParams.set('state', this.generateState());

    return authorizeUrl;
  }

  getPosterOAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.poster.apiUrl}/auth`);

    authorizeUrl.searchParams.set('application_id', this.integrationsConfig.poster.appId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.poster.redirectUri);
    authorizeUrl.searchParams.set('response_type', POSTER_RESPONSE_TYPE);

    return authorizeUrl;
  }

  getZidOAuthUrl(): URL {
    const authorizeUrl = new URL(`${this.integrationsConfig.zid.apiUrl}/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.zid.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.zid.redirectUri);
    authorizeUrl.searchParams.set('response_type', ZID_RESPONSE_TYPE);

    return authorizeUrl;
  }

  getShopifyAuthUrl(hostUrl: string): URL {
    sessionStorage.setItem(OAUTH2_HOST_SESSION_KEY, hostUrl);

    const authorizeUrl = new URL(`${hostUrl}admin/oauth/authorize`);

    authorizeUrl.searchParams.set('client_id', this.integrationsConfig.shopify.clientId);
    authorizeUrl.searchParams.set('redirect_uri', this.integrationsConfig.shopify.redirectUri);
    authorizeUrl.searchParams.set('state', this.generateState());
    authorizeUrl.searchParams.set('scope', SHOPIFY_SCOPE);

    return authorizeUrl;
  }

  private generateState(): string {
    const state = crypto.randomUUID();

    localStorage.setItem(PROVIDER_AUTH_STATE_KEY, state);

    return state;
  }
}
