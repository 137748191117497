<supy-grid class="supy-integrations-grid" [data]="integrations()" (cellClick)="onCellClick($event)">
  <supy-grid-column
    width="30%"
    header="Integration type"
    i18n-header="@@grid.headers.integrationType.label"
    field="category"
  >
    <ng-template supyGridCell let-value>{{ value | titlecase }}</ng-template>
  </supy-grid-column>
  <supy-grid-column width="30%" header="Third Party Name" i18n-header="@@grid.headers.thirdPartyName.label">
    <ng-template supyGridCell let-rowData="rowData">{{ getProviderDisplayName(rowData?.providerBrand) }}</ng-template>
  </supy-grid-column>
  <supy-grid-column width="30%" header="# of Tenants" i18n-header="@@grid.headers.numOfTenants.label">
    <ng-template supyGridCell let-rowData="rowData">
      {{ rowData.tenantsCount }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="10%">
    <ng-template supyGridCell>
      <supy-icon name="arrow-right-secondary" color="gray" size="small" />
    </ng-template>
  </supy-grid-column>
</supy-grid>
