<supy-dialog
  class="supy-select-tenant-sync-method"
  initialState="open"
  position="center"
  width="30vw"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header>
    <div class="supy-select-tenant-sync-method__header">
      <h3 i18n="@@integrations.selectTenantSyncMethod">Select The Tenant Sync Method</h3>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="supy-select-tenant-sync-method__content">
    <div>
      <supy-button-group [value]="method()" [buttons]="methods" (valueChange)="method.set($event)" />
    </div>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-select-tenant-sync-method__footer">
    <supy-button name="cancel" color="default" (buttonClick)="closeDialog()" i18n="@@cancel">Cancel</supy-button>
    <supy-button
      name="integration-enable"
      type="submit"
      color="primary"
      (buttonClick)="onSubmit()"
      i18n="@@common.actions.proceed"
      >Proceed</supy-button
    >
  </supy-dialog-footer>
</supy-dialog>
