<supy-dialog
  class="supy-create-branch-mapping-dialog"
  initialState="open"
  position="center"
  width="25vw"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header>
    <div class="supy-create-branch-mapping-dialog__header">
      <h3>
        <ng-container *ngIf="isEdit" i18n="@@integrations.mapping.editBranch">Edit Branch Mapping</ng-container>
        <ng-container *ngIf="!isEdit" i18n="@@integrations.mapping.connectSupyBranch">Connect Supy Branch</ng-container>
      </h3>
      <supy-separator type="solid" class="supy-create-branch-mapping-dialog__separator" />
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="supy-create-branch-mapping-dialog__content">
    <form class="supy-create-branch-mapping-dialog__form" [formGroup]="form">
      <div>
        <div class="supy-create-branch-mapping-dialog__form-icon">
          <span i18n="@@integrations.mapping.supyBranch">Supy Branch</span>
          <supy-icon
            name="info"
            size="small"
            i18n-title="@@integrations.mapping.cutOffTime"
            title="Business cutoff time at: {{ branchCutoffTime() }}"
          />
        </div>

        <supy-select name="supy-branch" [disabled]="true" [value]="branchWithMapping.branchId">
          <supy-select-item [value]="branchWithMapping.branchId">{{
            getLocalizedName(branchWithMapping.branchName) | titlecase
          }}</supy-select-item>
        </supy-select>
      </div>

      <div *ngIf="!isManualProviderBranch && providerBranches.length > 0">
        <span i18n="@@integrations.mapping.chooseBranch">Choose Branch</span>
        <supy-select
          formControlName="providerResource"
          name="provider-resource"
          i18n-placeholder="@@integrations.mapping.chooseBranch"
          placeholder="Choose Branch"
        >
          <supy-select-item *ngFor="let providerBranch of providerBranches" [value]="providerBranch">
            {{ providerBranch.displayName | titlecase }}
          </supy-select-item>
        </supy-select>
      </div>

      <div *ngIf="isManualProviderBranch">
        <span i18n="@@integrations.mapping.providerBranchId">Provider Branch Id</span>

        <supy-input
          formControlName="providerResource"
          name="provider-resource"
          i18n-placeholder="@@integrations.mapping.providerBranchId"
          placeholder="Provider Branch Id"
        />
      </div>

      <div *ngIf="showAutoSync" class="supy-create-branch-mapping-dialog__form-switch">
        <span i18n="@@integrations.mapping.automatedSync">Automated Sync</span>
        <supy-switch name="auto-sync" formControlName="autoSync" />
      </div>
    </form>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-create-branch-mapping-dialog__footer">
    <supy-button name="cancel" color="default" (buttonClick)="closeDialog()" i18n="@@cancel">Cancel</supy-button>
    <supy-button
      name="action-btn"
      color="secondary"
      [disabled]="form.invalid"
      (buttonClick)="isEdit ? onSave() : onConnect()"
    >
      <ng-container *ngIf="branchWithMapping" i18n="@@save">Save</ng-container>
      <ng-container *ngIf="!branchWithMapping" i18n="@@integrations.mapping.connectBranch">Connect Branch</ng-container>
    </supy-button>
  </supy-dialog-footer>
</supy-dialog>
