import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { TenantCategoryEnum } from '../../tenant';
import { MAPPING_BFF_URI } from '../config';
import {
  BranchesWithTenants,
  CreateMappingRequest,
  CreateMappingsRequest,
  DeleteMappingsRequest,
  GetMappingsRequest,
  Mapping,
  UpdateMappingRequest,
  UpdateMappingsRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  protected readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(MAPPING_BFF_URI);

  get(query: Query<GetMappingsRequest>): Observable<IQueryResponse<Mapping>> {
    return this.httpClient.get<IQueryResponse<Mapping>>(`${this.bffUri}`, {
      params: query.toQueryParams(),
    });
  }

  create(body: CreateMappingRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}`, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/${id}`);
  }

  update(id: string, body: UpdateMappingRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}`, body);
  }

  createMany(body: CreateMappingsRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/batch`, body);
  }

  deleteMany(body: DeleteMappingsRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/batch-delete`, body);
  }

  updateMany(body: UpdateMappingsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/batch`, body);
  }

  getBranchesWithTenants(retailerId: string, category?: TenantCategoryEnum): Observable<BranchesWithTenants> {
    return this.httpClient.get<BranchesWithTenants>(`${this.bffUri}/branches-with-tenants`, {
      params: { retailerId, ...(category ? { category } : {}) },
    });
  }
}
