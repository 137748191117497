import produce from 'immer';
import { catchError, concatMap, filter, from, mergeMap, scan, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, fetchInBatches, IQueryResponse, QueryBuilder } from '@supy/common';
import { SnackbarService } from '@supy/components';
import { InventoryRecipe, InventoryRecipeStateEnum } from '@supy/inventory';
import { CurrentRetailerState } from '@supy/retailers';
import { BaseChannel, WebSocketApp, WebSocketClient } from '@supy/websockets';

import {
  PosItem,
  PosItemRecipeRequestProps,
  PosItemRequestProps,
  PosItemsProviderSyncResponse,
  PosItemsProviderSyncStatus,
  PosItemsStats,
  PosItemStateEnum,
} from '../../models';
import { PosItemService } from '../../services';
import { downloadPosItemsReport } from '../../utils';
import {
  PosItemDisposeProviderSyncStatusListener,
  PosItemExport,
  PosItemGetMany,
  PosItemGetRecipes,
  PosItemGetStats,
  PosItemIgnore,
  PosItemInitFilters,
  PosItemManualSync,
  PosItemMap,
  PosItemMapMany,
  PosItemPatchFilterItems,
  PosItemPatchFilterRecipes,
  PosItemPatchRequestMeta,
  PosItemProviderSync,
  PosItemResetFilters,
  PosItemResetProviderSyncStatus,
  PosItemSetProviderSyncStatusListener,
  PosItemUnignore,
  PosItemUnmap,
} from '../actions';

export interface PosItemStateModel extends EntityListStateModel<PosItem> {
  readonly filters: PosItemFilters;
  readonly requestMetadata: PosItemRequestMetadata;
  readonly responseMetadata: PosItemResponseMetadata;
  readonly recipes: InventoryRecipe[];
  readonly stats: PosItemsStats | null;
  readonly isProviderSyncInProgress: boolean;
  readonly providerSyncStatus: PosItemsProviderSyncStatus | null;
}

export interface PosItemFilters {
  readonly itemCodeName: string | null;
  readonly recipeCodeName: string | null;
  readonly locationIds: string[];
  readonly categories: string[];
  readonly status: PosItemStateEnum;
  readonly tenant: string | null;
}

const POS_ITEM_STATE_TOKEN = new StateToken<PosItemStateModel[]>('PosItem');

const FILTERS_DEFAULT = {
  itemCodeName: null,
  recipeCodeName: null,
  locationIds: [],
  categories: [],
  status: PosItemStateEnum.Unmapped,
  tenant: null,
};

const EXPORT_POS_ITEMS_BATCH_SIZE = 500;

export interface PosItemRequestMetadata {
  readonly itemsLimit: number;
  readonly itemsPage: number;
  readonly recipesLimit: number;
  readonly recipesPage: number;
  readonly retailerId: string | null;
}

export interface PosItemResponseMetadata {
  readonly itemsTotal?: number;
  readonly itemsCount?: number;
  readonly recipesTotal?: number;
  readonly recipesCount?: number;
}

const REQUEST_META_DEFAULT = { itemsPage: 0, recipesPage: 0, itemsLimit: 50, recipesLimit: 50, retailerId: null };
const RESPONSE_META_DEFAULT = { itemsTotal: 0, itemsCount: 0, recipesCount: 0, recipesTotal: 0 };

@State<PosItemStateModel>({
  name: POS_ITEM_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    recipes: [],
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    stats: null,
    isProviderSyncInProgress: false,
    providerSyncStatus: null,
  },
})
@Injectable()
export class PosItemState extends EntityListState {
  protected readonly store = inject(Store);
  private readonly posItemService = inject(PosItemService);
  private readonly snackbarService = inject(SnackbarService);

  constructor(@Inject(WebSocketApp.Default) private readonly websocketClient: WebSocketClient) {
    super();
  }

  private syncStatusChannel: BaseChannel;

  @Selector()
  static items(state: PosItemStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static recipes(state: PosItemStateModel) {
    return state.recipes;
  }

  @Selector()
  static stats(state: PosItemStateModel) {
    return state.stats;
  }

  @Selector()
  static status(state: PosItemStateModel) {
    return state.filters.status;
  }

  @Selector()
  static appliedFiltersCount(state: PosItemStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT, ['tenant']);
  }

  @Selector()
  static itemNameFilter(state: PosItemStateModel) {
    return state.filters.itemCodeName;
  }

  @Selector()
  static filters(state: PosItemStateModel) {
    return EntityListState.currentFilters<PosItemFilters>(state);
  }

  @Selector()
  static requestMetadata(state: PosItemStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: PosItemStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static providerSyncStatus(state: PosItemStateModel) {
    return state.providerSyncStatus;
  }

  @Selector()
  static isProviderSyncInProgress(state: PosItemStateModel) {
    return state.isProviderSyncInProgress;
  }

  @Selector()
  static selectedTenantId(state: PosItemStateModel) {
    return state.filters.tenant;
  }

  @Action(PosItemInitFilters)
  posItemInitFilter(ctx: StateContext<PosItemStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(PosItemPatchFilterItems)
  patchPosItemFilterItems(ctx: StateContext<PosItemStateModel>, action: PosItemPatchFilterItems) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new PosItemPatchRequestMeta({ itemsPage: 0 }), new PosItemGetMany()]);
  }

  @Action(PosItemPatchFilterRecipes)
  patchPosItemsFilterRecipes(ctx: StateContext<PosItemStateModel>, action: PosItemPatchFilterRecipes) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new PosItemPatchRequestMeta({ recipesPage: 0 }), new PosItemGetRecipes()]);
  }

  @Action(PosItemResetFilters)
  resetInventoryRecipeFilter(ctx: StateContext<PosItemStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(PosItemGetMany, { cancelUncompleted: true })
  getPosItems(ctx: StateContext<PosItemStateModel>) {
    return this.posItemService.get(this.getItemsQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          responseMetadata: {
            ...ctx.getState().responseMetadata,
            itemsCount: metadata.count,
            itemsTotal: metadata.total,
          },
        });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(PosItemGetRecipes)
  getRecipes(ctx: StateContext<PosItemStateModel>) {
    return this.posItemService.getRecipes(this.getRecipesQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({
          recipes: data,
          responseMetadata: {
            ...ctx.getState().responseMetadata,
            recipesCount: metadata.count,
            recipesTotal: metadata.total,
          },
        });
      }),
    );
  }

  @Action(PosItemGetStats)
  getPosItemsStats(ctx: StateContext<PosItemStateModel>) {
    return this.posItemService
      .getStats(ctx.getState().filters.tenant as string)
      .pipe(tap(stats => ctx.patchState({ stats })));
  }

  @Action(PosItemProviderSync)
  syncItemsFromProvider(ctx: StateContext<PosItemStateModel>, { tenantId }: PosItemProviderSync) {
    ctx.patchState({ providerSyncStatus: null, isProviderSyncInProgress: true });

    return this.posItemService.providerSync(tenantId).pipe(
      catchError((error: HttpErrorResponse) => {
        ctx.patchState({ isProviderSyncInProgress: false, providerSyncStatus: 'error' });

        return throwError(() => error);
      }),
    );
  }

  @Action(PosItemSetProviderSyncStatusListener)
  setProviderSyncStatusListener(ctx: StateContext<PosItemStateModel>) {
    const channelName = 'pos-item-sync';
    const eventName = `${channelName}.status-updated`;
    const tenantId = ctx.getState().filters.tenant as string;

    this.syncStatusChannel = this.websocketClient.subscribe(`private-${channelName}-${tenantId}`);

    this.syncStatusChannel.on(eventName, (resp: PosItemsProviderSyncResponse) =>
      this.providerSyncStatusListenerCb(ctx)(resp),
    );
  }

  @Action(PosItemDisposeProviderSyncStatusListener)
  disposeProviderSyncStatusListener(ctx: StateContext<PosItemStateModel>) {
    this.syncStatusChannel?.dispose();
  }

  @Action(PosItemResetProviderSyncStatus)
  resetProviderSyncStatus(ctx: StateContext<PosItemStateModel>) {
    ctx.patchState({ providerSyncStatus: null, isProviderSyncInProgress: false });
  }

  @Action(PosItemManualSync)
  manualSync(ctx: StateContext<PosItemStateModel>, { payload }: PosItemManualSync) {
    return this.posItemService
      .manualSync(payload.body)
      .pipe(
        mergeMap(() =>
          ctx.dispatch([new PosItemPatchRequestMeta({ itemsPage: 0 }), new PosItemGetMany(), new PosItemGetStats()]),
        ),
      );
  }

  @Action(PosItemMapMany)
  mapPosItemMany(ctx: StateContext<PosItemStateModel>, { payload }: PosItemMapMany) {
    return this.posItemService
      .mapItems(payload)
      .pipe(mergeMap(() => ctx.dispatch([new PosItemGetStats(), new PosItemGetMany(), new PosItemGetRecipes()])));
  }

  @Action(PosItemMap)
  mapPosItem(ctx: StateContext<PosItemStateModel>, { id, payload }: PosItemMap) {
    return this.posItemService.mapItem(id, { ...payload }).pipe(
      tap(updatedItem => super.setOne(ctx, updatedItem)),
      mergeMap(() => ctx.dispatch([new PosItemGetStats(), new PosItemGetRecipes()])),
    );
  }

  @Action(PosItemUnmap)
  unmapPosItem(ctx: StateContext<PosItemStateModel>, { id }: PosItemUnmap) {
    return this.posItemService.unmapItem(id).pipe(
      tap(updatedItem => super.setOne(ctx, updatedItem)),
      mergeMap(() => ctx.dispatch([new PosItemGetStats(), new PosItemGetMany(), new PosItemGetRecipes()])),
    );
  }

  @Action(PosItemUnignore)
  unignorePosItem(ctx: StateContext<PosItemStateModel>, { id, requestBody }: PosItemUnignore) {
    return this.posItemService.unignoreItem(id, requestBody).pipe(
      tap(updatedItem => super.setOne(ctx, updatedItem)),
      mergeMap(() => ctx.dispatch([new PosItemGetStats(), new PosItemGetMany()])),
    );
  }

  @Action(PosItemIgnore)
  ignorePosItem(ctx: StateContext<PosItemStateModel>, { id, requestBody }: PosItemIgnore) {
    return this.posItemService.ignoreItem(id, requestBody).pipe(
      tap(updatedItem => super.setOne(ctx, updatedItem)),
      mergeMap(() => ctx.dispatch([new PosItemGetStats(), new PosItemGetMany()])),
    );
  }

  @Action(PosItemPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<PosItemStateModel>, { payload }: PosItemPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(PosItemExport)
  exportPosItems(ctx: StateContext<PosItemStateModel>, { tenantName }: PosItemExport) {
    const stats = PosItemState.stats(ctx.getState()) as PosItemsStats;

    const { unmapped, mapped, ignored } = stats;

    const statesToFetch = Object.values(PosItemStateEnum).filter(
      state =>
        (state === PosItemStateEnum.Unmapped && unmapped) ||
        (state === PosItemStateEnum.Mapped && mapped) ||
        (state === PosItemStateEnum.Ignored && ignored),
    );

    return from(statesToFetch).pipe(
      concatMap(posItemState =>
        fetchInBatches(
          this.posItemService.get.bind(this.posItemService),
          this.getItemsQuery(ctx.getState(), posItemState),
          EXPORT_POS_ITEMS_BATCH_SIZE,
        ),
      ),
      scan(
        (group: IQueryResponse<PosItem>, posItems: IQueryResponse<PosItem>) => ({
          data: [...group.data, ...posItems.data],
          metadata: {
            count: group.metadata.count + posItems.metadata.count,
            total: group.metadata.total + posItems.metadata.total,
          },
        }),
        { data: [], metadata: { count: 0, total: 0 } } as IQueryResponse<PosItem>,
      ),
      filter(posItems => !!posItems.data?.find(posItem => posItem.state === [...statesToFetch].pop())),
      tap(({ data }) => void downloadPosItemsReport(data, tenantName)),
    );
  }

  private providerSyncStatusListenerCb(ctx: StateContext<PosItemStateModel>) {
    return ({ message, status }: PosItemsProviderSyncResponse) => {
      const syncInProgress = !['not-started', 'done', 'error'].includes(status);

      if (!syncInProgress) {
        ctx.dispatch(new PosItemDisposeProviderSyncStatusListener());
      }

      if (status === 'done') {
        ctx.dispatch([new PosItemGetMany(), new PosItemGetStats()]);
      }

      if (message) {
        this.snackbarService.open(message, { variant: status === 'done' ? 'success' : 'error' });
      }

      ctx.patchState({ providerSyncStatus: status, isProviderSyncInProgress: syncInProgress });
    };
  }

  private getItemsQuery(state: PosItemStateModel, posItemState?: string) {
    const { itemCodeName, status, tenant } = state.filters;

    const qb = new QueryBuilder<PosItem & PosItemRequestProps>({
      filtering: [
        {
          by: 'state',
          match: posItemState ?? status,
          op: 'eq',
        },
      ],
      paging: {
        offset: state.requestMetadata.itemsPage * state.requestMetadata.itemsLimit,
        limit: state.requestMetadata.itemsLimit,
      },
      ordering: [
        {
          by: 'state',
          dir: 'desc',
        },
        {
          by: 'name.en',
          dir: 'asc',
        },
        {
          by: 'id',
          dir: 'desc',
        },
      ],
    });

    if (tenant) {
      qb.filtering.setFilter({
        by: 'tenantId',
        match: tenant,
        op: 'eq',
      });
    }

    if (itemCodeName) {
      qb.filtering.withGroup('or', [
        {
          by: 'code',
          match: itemCodeName,
          op: 'like',
        },
        {
          by: 'name.en',
          match: itemCodeName,
          op: 'like',
        },
      ]);
    }

    return qb.build();
  }

  private getRecipesQuery(state: PosItemStateModel) {
    const { recipeCodeName, locationIds, categories } = state.filters;

    const qb = new QueryBuilder<InventoryRecipe & PosItemRecipeRequestProps>({
      filtering: [
        {
          by: 'state',
          match: [InventoryRecipeStateEnum.Available],
          op: 'in',
        },
        {
          by: 'type',
          match: 'finished',
          op: 'eq',
        },
      ],
      paging: {
        offset: state.requestMetadata.recipesPage * state.requestMetadata.recipesLimit,
        limit: state.requestMetadata.recipesLimit,
      },
      ordering: [
        {
          by: 'state',
          dir: 'desc',
        },
        {
          by: 'id',
          dir: 'desc',
        },
      ],
    });

    if (recipeCodeName) {
      qb.filtering.withGroup('or', [
        {
          by: 'code',
          match: recipeCodeName,
          op: 'like',
        },
        {
          by: 'name.en',
          match: recipeCodeName,
          op: 'like',
        },
      ]);
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: categories,
        op: 'in',
      });
    }

    qb.filtering.setFilter({
      by: 'locations.locationId',
      match:
        locationIds?.length > 0
          ? locationIds
          : this.store.selectSnapshot(CurrentRetailerState.branches).map(({ id }) => id),
      op: 'in',
    });

    return qb.build();
  }
}
