<supy-grid class="supy-mapping-branches-grid" primaryKey="id" [data]="branchesWithMappings()">
  <supy-grid-column width="35%" header="Supy Branches" i18n-header="@@grid.headers.supyBranches.label" field="name">
    <ng-template supyGridCell let-rowData="rowData">
      <span class="supy-mapping-branches-grid__branch-title">{{ getLocalizedName(rowData?.branchName) }}</span>
      <div
        *ngIf="alreadyMappedInOtherProviders(rowData)?.length"
        [supyTooltipDeprecated]="providersTooltip"
        position="right"
        class="supy-mapping-branches-grid__branch-title-info"
      >
        <supy-icon name="info" size="small" />
      </div>
      <supy-tooltip-deprecated #providersTooltip>
        <span class="supy-mapping-branches-grid__tooltip" i18n="@@integrations.mapping.branchHasBeenMapped">
          This branch has been already mapped in:
          {{ providersAsString(alreadyMappedInOtherProviders(rowData) ?? []) }}
        </span></supy-tooltip-deprecated
      >
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="15%">
    <ng-template supyGridCell let-rowData="rowData">
      <div>
        <supy-button
          *ngIf="rowData?.state === mappingState.Active; else notConnected"
          name="unlink"
          i18n-title="@@integrations.mapping.unlink"
          title="Unlink"
          (buttonClick)="unlinkClick.emit(rowData)"
        >
          <div class="supy-mapping-branches-grid__row">
            <span class="supy-mapping-branches-grid__text--error" i18n="@@integrations.mapping.unlink">Unlink</span>
            <supy-icon name="unlink-2" size="small" color="error" />
          </div>
        </supy-button>
        <ng-template #notConnected>
          <supy-button
            [disabled]="connectionDisabled"
            name="connect"
            i18n-title="@@integrations.mapping.connect"
            title="Connect"
            (buttonClick)="connectClick.emit(rowData)"
          >
            <div class="supy-mapping-branches-grid__row">
              <span class="supy-mapping-branches-grid__text--info" i18n="@@integrations.mapping.connect">Connect</span>
              <supy-icon name="arrow-swap-horizontal" size="small" color="info" />
            </div>
          </supy-button>
        </ng-template>
      </div>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    width="30%"
    header="{{ getProviderDisplayName(tenant.providerBrand) | titlecase }} Branch"
    i18n-header="@@grid.headers.someBranch.label"
  >
    <ng-template supyGridCell let-rowData="rowData">
      {{ getProviderBranchName(rowData?.providerResource?.id) }}
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="15%">
    <ng-template supyGridCellHeader>
      <span i18n="@@integrations.mapping.autoSync">Auto Sync</span>
      <supy-icon name="info" size="small" [supyTooltipDeprecated]="autoSyncTooltip" position="left" />
      <supy-tooltip-deprecated #autoSyncTooltip>
        <small class="supy-mapping-branches-grid__tooltip" i18n="@@integrations.mapping.autoSyncTooltip"
          >Automatically creates a sales import once per day, at the business day cutoff time</small
        >
      </supy-tooltip-deprecated>
    </ng-template>
    <ng-template supyGridCell let-rowData="rowData">
      <span *ngIf="rowData?.metadata?.autoSync" i18n="@@yes">Yes</span>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="5%">
    <ng-template supyGridCell let-rowData="rowData">
      <supy-icon name="edit" color="info" size="small" class="cursor--pointer" (click)="editClick.emit(rowData)" />
    </ng-template>
  </supy-grid-column>
</supy-grid>
