import produce from 'immer';
import { tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { Query, QueryPaging } from '@supy/common';

import { BranchWithTenants, Mapping, MappingStateEnum } from '../../models';
import { MappingService } from '../../services';
import {
  MappingCreate,
  MappingCreateMany,
  MappingDelete,
  MappingDeleteMany,
  MappingGetBranchesWithTenants,
  MappingGetMany,
  MappingUpdate,
  MappingUpdateMany,
} from '../actions';

export interface MappingStateModel {
  readonly mappings: Mapping[];
  readonly branchesWithTenants: BranchWithTenants[];
}

const MAPPING_STATE_TOKEN = new StateToken<MappingStateModel>('mapping');

@State<MappingStateModel>({
  name: MAPPING_STATE_TOKEN,
  defaults: {
    mappings: [],
    branchesWithTenants: [],
  },
})
@Injectable()
export class MappingState {
  private readonly mappingService = inject(MappingService);

  static mapping(id: string) {
    return createSelector([MAPPING_STATE_TOKEN], (state: MappingStateModel) => {
      return state.mappings.find(mapping => mapping.id === id);
    });
  }

  @Selector()
  static mappings(state: MappingStateModel) {
    return state.mappings;
  }

  @Selector()
  static branchesWithTenants(state: MappingStateModel) {
    return state.branchesWithTenants;
  }

  static tenantByBranch(tenantId: string, branchId: string) {
    return createSelector([MAPPING_STATE_TOKEN], (state: MappingStateModel) => {
      return state.branchesWithTenants
        ?.find(({ branch }) => branch.id === branchId)
        ?.tenants.find(({ id }) => id === tenantId);
    });
  }

  @Action(MappingGetMany)
  getMappings(ctx: StateContext<MappingStateModel>, { retailerId, tenantId, resourceType, category }: MappingGetMany) {
    return this.mappingService
      .get(
        new Query<Mapping>({
          filtering: [
            { by: 'tenantId', match: tenantId, op: 'eq' },
            { by: 'retailerId', match: retailerId, op: 'eq' },
            { by: 'state', match: MappingStateEnum.Active, op: 'eq' },
            { by: 'resourceType', match: resourceType, op: 'eq' },
            { by: 'category', match: category, op: 'eq' },
          ],
          paging: QueryPaging.NoLimit,
        }),
      )
      .pipe(
        tap(({ data }) =>
          ctx.setState(
            produce(draft => {
              draft.mappings = data;
            }),
          ),
        ),
      );
  }

  @Action(MappingGetBranchesWithTenants, { cancelUncompleted: true })
  getBranchesWithTenants(
    ctx: StateContext<MappingStateModel>,
    { retailerId, category }: MappingGetBranchesWithTenants,
  ) {
    return this.mappingService
      .getBranchesWithTenants(retailerId, category)
      .pipe(tap(data => ctx.patchState({ branchesWithTenants: data.branches })));
  }

  @Action(MappingCreate)
  createMapping(ctx: StateContext<MappingStateModel>, { payload }: MappingCreate) {
    return this.mappingService.create(payload);
  }

  @Action(MappingCreateMany)
  createMappings(ctx: StateContext<MappingStateModel>, { payload }: MappingCreateMany) {
    return this.mappingService.createMany(payload);
  }

  @Action(MappingUpdate)
  updateMapping(ctx: StateContext<MappingStateModel>, { id, payload }: MappingUpdate) {
    return this.mappingService.update(id, payload);
  }

  @Action(MappingUpdateMany)
  updateMappings(ctx: StateContext<MappingStateModel>, { payload }: MappingUpdateMany) {
    return this.mappingService.updateMany(payload);
  }

  @Action(MappingDelete)
  deleteMapping(ctx: StateContext<MappingStateModel>, { id }: MappingDelete) {
    return this.mappingService.delete(id);
  }

  @Action(MappingDeleteMany)
  deleteMappings(ctx: StateContext<MappingStateModel>, { payload }: MappingDeleteMany) {
    return this.mappingService.deleteMany(payload);
  }
}
