<div class="supy-add-sales-types-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    width="40vw"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <h3 class="supy-add-sales-types-dialog__header-title" i18n="@@integrations.mapping.manuallyAddSalesTypes">
        Manually Add Sale Types
      </h3>
    </supy-dialog-header>
    <supy-dialog-content class="supy-add-sales-types-dialog__body">
      <div class="supy-add-sales-types-dialog__body-banner">
        <supy-icon name="info" color="warn" size="small" />
        <span class="supy-add-sales-types-dialog__body-banner--text">Each code and name must be unique!</span>
      </div>
      <div *ngFor="let row of rows(); let index = index" class="supy-add-sales-types-dialog__body-row">
        <div class="supy-add-sales-types-dialog__body-row-inputs">
          <supy-input
            class="supy-add-sales-types-dialog__body-row-input"
            i18n-placeholder="@@common.salesTypeCode"
            placeholder="Sales Type Code"
            name="sales-type-code"
            (valueChange)="row.salesType.code = $event"
          />
          <supy-input
            class="supy-add-sales-types-dialog__body-row-input"
            i18n-placeholder="@@integrations.mapping.salesTypeName"
            placeholder="Sale Type Name"
            name="sales-type-name"
            (valueChange)="row.salesType.name = $event"
          />
        </div>

        <div class="supy-add-sales-types-dialog__body-row-actions">
          <supy-button
            color="error"
            name="cancel"
            variant="flat"
            [disabled]="index === 0"
            (buttonClick)="onDeleteRowClick(row.index)"
          >
            <supy-icon name="trash" />
          </supy-button>
        </div>
      </div>

      <div class="supy-add-sales-types-dialog__body-row">
        <supy-button
          (buttonClick)="onAddRowClick()"
          color="secondary"
          name="add-new-row"
          variant="flat"
          i18n="@@common.actions.addNewRow"
          >Add new row</supy-button
        >
      </div>
    </supy-dialog-content>
    <supy-dialog-footer>
      <div class="supy-add-sales-types-dialog__buttons">
        <supy-button (buttonClick)="onCancelClick()" color="default" name="cancel">Cancel</supy-button>
        <supy-button
          [disabled]="!isValid()"
          (buttonClick)="onConfirmClick()"
          color="primary"
          name="confirm"
          i18n="@@save"
          >Save</supy-button
        >
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
