import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { PosIntegrationActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
export class ManagePosTenants extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.PosIntegration,
      action: PosIntegrationActionEnum.ManageTenants,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdatePosMappingItem extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.PosIntegration,
      action: PosIntegrationActionEnum.UpdateItemMapping,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class CreateImport extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.PosIntegration,
      action: PosIntegrationActionEnum.ImportSales,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ProcessImport extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.PosIntegration,
      action: PosIntegrationActionEnum.ProcessImport,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class DeleteImport extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.PosIntegration,
      action: PosIntegrationActionEnum.DeleteImport,
      resource,
      principal,
    });
  }
}

export class PosIntegrationPermissionsStrategy {
  static ManageTenants = ManagePosTenants;
  static UpdateMappingItem = UpdatePosMappingItem;
  static CreateImport = CreateImport;
  static ProcessImport = ProcessImport;
  static DeleteImport = DeleteImport;
}
