<form class="supy-sales-import-details" [class.readonly]="isReadonly" [formGroup]="form">
  <div class="supy-sales-import-details__section">
    <div class="supy-sales-import-details__section-header">
      <h5 class="supy-sales-import-details__section-title" i18n="@@integrations.salesImportDetails">
        Sales Import Details
      </h5>
    </div>

    <div class="supy-sales-import-details__form-row">
      <div class="supy-sales-import-details__form-input">
        <small i18n="@@integrations.description"
          >Description <sup class="supy-sales-import-details__form-sup">*</sup></small
        >
        <supy-input
          name="description"
          formControlName="description"
          i18n-placeholder="@@integrations.importDescription"
          placeholder="Import Description"
        />
      </div>
      <div class="supy-sales-import-details__form-input">
        <div class="supy-sales-import-details__form-input-label">
          <small i18n="@@integrations.branch">Branch <sup class="supy-sales-import-details__form-sup">*</sup> </small>
          <supy-loader *ngIf="!isReadonly && !branchesWithTenants?.length" size="small" />
        </div>

        <supy-select
          *ngIf="!isReadonly"
          i18n-placeholder="@@branchSelect"
          placeholder="Select Branch"
          name="branch"
          formControlName="branch"
          density="small"
          (selected)="onBranchSelected($event.id)"
        >
          <supy-select-item *ngFor="let branchWithTenants of branchesWithTenants" [value]="branchWithTenants.branch">
            <div class="supy-sales-import-details__form-input-branch">
              <div class="supy-sales-import-details__form-input-branch-title">
                {{ getLocalizedName(branchWithTenants.branch.name) }}
              </div>

              <supy-icon *ngIf="branchWithTenants.tenants?.length > 1" name="link-circle" color="success" />
            </div>
          </supy-select-item>
        </supy-select>

        <supy-input
          *ngIf="isReadonly"
          i18n-placeholder="@@branch"
          placeholder="Branch"
          [disabled]="true"
          [value]="getLocalizedName(salesImport?.branch.name)"
        />
      </div>
    </div>
  </div>

  @if (!isReadonly) {
    <div class="supy-sales-import-details__section">
      <div class="supy-sales-import-details__section-header">
        <div class="supy-sales-import-details__section-title-wrapper">
          <div class="supy-sales-import-details__row">
            <h5 class="supy-sales-import-details__section-title" i18n="@@integrations.syncData">Sync Data</h5>
          </div>
          <div class="supy-sales-import-details__banner">
            <supy-icon name="info" color="warn" size="small" />
            <span class="supy-sales-import-details__banner--text"
              >Larger sales import will be split into chunks of one week each!</span
            >
          </div>
        </div>
      </div>

      <div class="supy-sales-import-details__form-row">
        <div class="supy-sales-import-details__form-input">
          <div class="supy-sales-import-details__form-input-label">
            <small i18n="@@integrations.tenant">Tenant <sup class="supy-sales-import-details__form-sup">*</sup> </small>
            <supy-loader *ngIf="!isReadonly && !branchesWithTenants?.length" size="small"></supy-loader>
          </div>

          <supy-select
            [disabled]="!isBranchProviderAuth()"
            i18n-placeholder="@@integrations.selectTenant"
            placeholder="Select Tenant"
            name="tenant"
            formControlName="tenant"
            density="small"
            (selected)="onTenantSelected($event)"
          >
            <supy-select-item *ngFor="let tenant of branchTenants" [value]="tenant">
              <div>
                <div>{{ tenant.name }}</div>
              </div>
            </supy-select-item>
          </supy-select>
          <supy-input
            *ngIf="isReadonly"
            i18n-placeholder="@@branch"
            placeholder="Branch"
            [disabled]="true"
            [value]="salesImport?.branch.name.en"
          ></supy-input>
        </div>
        <div class="supy-sales-import-details__form-input">
          <div class="supy-sales-import-details__form-input-label">
            <small i18n="@@integrations.dateRange">Select Available Dates</small>
            <supy-icon [supyTooltipDeprecated]="fromToTooltip" name="info" size="small"></supy-icon>
            <supy-tooltip-deprecated #fromToTooltip>
              <small>From and To parameters are required for pos sync</small>
            </supy-tooltip-deprecated>
            <supy-loader *ngIf="areAvailableDatesLoading" size="small"></supy-loader>
          </div>

          <supy-calendar-picker
            i18n-placeholder="@@common.selectDates"
            placeholder="Select Dates"
            formControlName="dateRange"
            [resetViewDate]="false"
            [requiresSorting]="false"
            [specialHighlighted]="false"
            [disabled]="!isBranchProviderAuth() || areAvailableDatesLoading"
            [specialDates]="availableDates()"
            [canSelectSingleDate]="true"
            (valueChanged)="onDateRangePick($event)"
          ></supy-calendar-picker>
        </div>

        <supy-dropdown-toggle
          class="supy-sales-import-details__button"
          [dropdownRef]="syncDropdown"
          position="bottom-left"
          action
        >
          <supy-button color="secondary">
            <span *ngIf="!isProviderSyncInProgress">Sync Data</span>
            <span *ngIf="isProviderSyncInProgress" i18n="@@common.syncing">Syncing...</span>

            <supy-icon
              *ngIf="!isProviderSyncInProgress"
              class="nav-drawer-icon"
              name="arrow-down"
              size="medium"
            ></supy-icon>
            <supy-loader *ngIf="isProviderSyncInProgress"></supy-loader>
          </supy-button>
        </supy-dropdown-toggle>
        <supy-dropdown #syncDropdown action>
          <supy-dropdown-item
            [disabled]="!form.valid || !isProviderSyncPossible || isProviderSyncInProgress"
            (click)="!isProviderSyncInProgress && onProviderSyncClick()"
          >
            <span>Fetch from POS</span>
          </supy-dropdown-item>
          <supy-dropdown-item [disabled]="!form.valid || isProviderSyncInProgress" (click)="onManualSyncClick()">
            <span>Manual upload</span>
          </supy-dropdown-item>
        </supy-dropdown>
      </div>
    </div>
  }
  @if (isReadonly && !hidePrices()) {
    <div class="supy-sales-import-details__section">
      <div class="supy-sales-import-details__section-header">
        <h5 class="supy-sales-import-details__section-title" i18n="@@integrations.dataSource">Data Source</h5>
      </div>

      <div class="supy-sales-import-details__section-content">
        <div *ngIf="salesImport?.metadata?.fileUrl" class="supy-sales-import-details__download-source">
          <small i18n="@@integrations.downloadExcelSalesData">Download your sales data in Excel format.</small>
          <a class="supy-sales-import-details__uploaded-file" [href]="salesImport.metadata?.fileUrl" target="_blank">
            <supy-icon name="excel"></supy-icon>
            <small class="supy-sales-import-details__uploaded-file--title" i18n="@@integrations.clickToDownload"
              >Click to download your file</small
            >
          </a>
        </div>

        <small
          class="supy-sales-import-details__sync-source"
          *ngIf="salesImport && !salesImport?.metadata?.fileUrl"
          i18n="@@integrations.itemsSynced"
        >
          Items have been synced from <strong> {{ tenant?.name }} </strong> tenant.
        </small>
      </div>
    </div>

    <div class="supy-sales-import-details__section">
      <div class="supy-sales-import-details__section-header">
        <h5 class="supy-sales-import-details__section-title">
          {{ totalsHeader }}
        </h5>

        <supy-switch
          *ngIf="salesImport?.statistics.totalIgnoredItems"
          name="ignored-filter"
          labelPosition="before"
          size="small"
          [value]="isIgnoredDisplayed"
          (valueChange)="onToggleIgnored()"
          i18n="@@integrations.showIgnored"
          >Show Ignored</supy-switch
        >
      </div>

      <div class="supy-sales-import-details__section-content">
        <div class="supy-sales-import-details__stats">
          <div class="supy-sales-import-details__stats-row">
            <div class="supy-sales-import-details__stats-title" i18n="@@integrations.uniquePosItems">
              Unique POS Items
            </div>
            <div class="supy-sales-import-details__stats-value">
              {{ (isIgnoredDisplayed ? uniqueIgnoredItemsCount : salesImport?.statistics.totalSyncedItems) ?? 0 }}
            </div>
          </div>
          <div class="supy-sales-import-details__stats-row">
            <div class="supy-sales-import-details__stats-title" i18n="@@integrations.discountValue">Discount Value</div>
            <div class="supy-sales-import-details__stats-value">
              {{
                (isIgnoredDisplayed ? salesImport?.totalIgnoredSales.discount : salesImport?.totalSales.discount) ?? 0
                  | supyPrecision: currencyPrecision
              }}{{ currency }}
            </div>
          </div>
          <div class="supy-sales-import-details__stats-row">
            <div class="supy-sales-import-details__stats-title" i18n="@@integrations.salesNoTax">Sales (excl. tax)</div>
            <div class="supy-sales-import-details__stats-value">
              {{
                (isIgnoredDisplayed ? salesImport?.totalIgnoredSales.exclTax : salesImport?.totalSales.exclTax) ?? 0
                  | supyPrecision: currencyPrecision
              }}{{ currency }}
            </div>
          </div>
          <div class="supy-sales-import-details__stats-row">
            <div class="supy-sales-import-details__stats-title" i18n="@@integrations.salesWithTax">
              Sales (incl. tax)
            </div>
            <div class="supy-sales-import-details__stats-value">
              {{
                (isIgnoredDisplayed ? salesImport?.totalIgnoredSales?.inclTax : salesImport?.totalSales.inclTax) ?? 0
                  | supyPrecision: currencyPrecision
              }}{{ currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</form>
