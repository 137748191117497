<supy-dialog
  class="supy-import-accounting-resources-dialog"
  initialState="open"
  position="center"
  width="50rem"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header class="supy-import-accounting-resources-dialog__header">
    <h3 i18n="@@common.actions.import">Import</h3>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-import-accounting-resources-dialog__content">
      <div class="supy-import-accounting-resources-dialog__content-block">
        <p i18n="@@integrations.posMapping.importDialog.templateInfo">
          Supy provides a template for easy import of sales items data into the system. You may click the button below
          to download. Input the data as per the template to upload easily and avoid any issues.
        </p>
        <a
          class="supy-import-accounting-resources-dialog__content-link"
          href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRCvR3-nyew_Sv3pENKzEAj6lOM8_678YGyxfr2LP2G2WmVfGkrN2zZmcbHBMtGdK1J1Ks0QyqyXEED/pub?output=xlsx"
          i18n="@@integrations.downloadTemplate"
        >
          <supy-icon name="document-download" color="primary" />
          Download Template
        </a>
      </div>
      <div class="supy-import-accounting-resources-dialog__content-block">
        <supy-file-upload
          [maxUploads]="1"
          [maxFileSize]="maxFileSize"
          [fileTypes]="[mimeType.xls, mimeType.xlsx, mimeType.csv]"
          (changeFiles)="onRemoveFile()"
          (localFileUploaded)="onLocalFileUploaded($event)"
        />
        <ng-container *ngIf="sheetNames().length">
          <div>
            <span i18n="@@integrations.posMapping.importDialog.selectSheet">Select the sheet to upload</span>

            <supy-select
              name="sheet-select"
              i18n-placeholder="@@common.selectSheet"
              placeholder="Select Sheet"
              (selected)="onSheetSelect($event)"
            >
              <supy-select-item *ngFor="let sheetName of sheetNames(); trackBy: trackByIndex" [value]="sheetName">
                {{ sheetName }}
              </supy-select-item>
            </supy-select>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="supy-import-accounting-resources-dialog__footer">
      <supy-button name="import-sales-items" color="secondary" (buttonClick)="closeDialog()">
        <span i18n="@@cancel">Cancel</span>
      </supy-button>
      <supy-button
        name="import-sales-items"
        color="primary"
        [disabled]="!sheetItems().length"
        (buttonClick)="onSubmit()"
      >
        <span i18n="@@integrations.posMapping.importDialog.submit">Submit Upload</span>
      </supy-button>
    </div>
  </supy-dialog-content>
</supy-dialog>
