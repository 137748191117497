import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import {
  convertUTCToLocalDate,
  Destroyable,
  getRetailerTimeZoneHelperMessage,
  LocalizedSkeletonObjectType,
  MimeType,
  TimezonePipe,
} from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { BranchTenant, BranchWithTenants } from '../../../mapping';
import { INTEGRATION_PROVIDER_CONFIG, ProviderEnum } from '../../../tenant';
import { SalesImport } from '../../models';

export interface SalesImportFormDetails {
  readonly description: string;
  readonly branch: LocalizedSkeletonObjectType;
  readonly tenant: BranchTenant;
  readonly syncFrom: Date;
  readonly syncTo: Date;
}

@Component({
  selector: 'supy-sales-import-details',
  templateUrl: './sales-import-details.component.html',
  styleUrls: ['./sales-import-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesImportDetailsComponent extends Destroyable implements OnDestroy, OnChanges {
  @Input() readonly isReadonly: boolean;
  @Input() readonly salesImport: SalesImport;
  @Input() readonly tenant: BranchTenant;
  @Input() readonly branchesWithTenants: BranchWithTenants[];
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly utcOffset: number;
  @Input() readonly isProviderSyncInProgress: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isIgnoredDisplayed: boolean;
  @Input() readonly uniqueIgnoredItemsCount: number;
  @Input() readonly areAvailableDatesLoading: boolean;
  @Input() set syncAvailableDates(value: Date[]) {
    this.defaultAvailableDates = value?.length ? value.map(date => this.applyTimezoneOffset(date)) : [new Date(0)];
    this.availableDates.set(this.defaultAvailableDates);
  }

  readonly hidePrices = input<boolean>();

  protected readonly isBranchProviderAuth = signal<boolean>(false);

  protected readonly sheetNames = signal<string[]>([]);
  protected readonly mimeType = MimeType;
  protected readonly maxFileSize = 5000000;

  protected defaultAvailableDates: Date[] = [];
  protected readonly availableDates = signal<Date[]>([]);

  protected readonly providers = ProviderEnum;
  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  private readonly timeZonePipe = new TimezonePipe();

  @Output() readonly tenantSelected = new EventEmitter<{ branchId: string; tenant: BranchTenant }>();
  @Output() readonly branchSelected = new EventEmitter<{ branchId: string }>();
  @Output() readonly manualSync = new EventEmitter<SalesImportFormDetails>();
  @Output() readonly providerSync = new EventEmitter<SalesImportFormDetails>();

  @Output() readonly toggleIgnored = new EventEmitter<void>();

  protected readonly form = new FormGroup({
    description: new FormControl<string | null>(null, [Validators.required]),
    branch: new FormControl<LocalizedSkeletonObjectType | null>(null, [Validators.required]),
    tenant: new FormControl<BranchTenant | null>(null, [Validators.required]),
    dateRange: new FormControl<[Date, Date] | null>(null),
  });

  get branchTenants(): BranchTenant[] {
    return this.branchesWithTenants.find(({ branch }) => this.form.value.branch?.id === branch.id)?.tenants ?? [];
  }

  get selectedTenant(): BranchTenant | null | undefined {
    return this.form.value.tenant;
  }

  get isProviderSyncPossible(): boolean {
    const currentTenant = this.form.value.tenant;

    return !!(
      currentTenant &&
      currentTenant.providerBrand !== ProviderEnum.Manual &&
      !currentTenant.blockProviderSalesImportSync &&
      this.form.value.dateRange
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.salesImport?.currentValue as SalesImport) || (changes.tenant?.currentValue as BranchTenant)) {
      this.form.patchValue({
        description: this.salesImport.description,
        branch: this.salesImport.branch,
        tenant: this.tenant,
      });

      this.form.disable();
    }
  }

  getProviderDisplayName(provider: ProviderEnum) {
    return `${INTEGRATION_PROVIDER_CONFIG[provider]?.displayName}`;
  }

  get totalsHeader(): string {
    return this.isIgnoredDisplayed
      ? $localize`:@@integrations.ignoredTotals: Ignored Totals`
      : $localize`:@@integrations.processedTotals: Processed Totals`;
  }

  onProviderSyncClick(): void {
    const syncFrom = convertUTCToLocalDate(this.form.value?.dateRange?.[0] as Date);
    const syncTo = convertUTCToLocalDate(this.form.value?.dateRange?.[1] as Date);

    this.providerSync.emit({ ...this.form.value, syncFrom, syncTo } as SalesImportFormDetails);
  }

  onDateRangePick(dateRange: Date[] | Date): void {
    if (!dateRange || !(dateRange as Date[])?.length) {
      this.availableDates.set(this.defaultAvailableDates);

      return;
    }

    const dayInMs = 86400000;
    const updatedDates = [];
    const startDate = this.applyTimezoneOffset(Array.isArray(dateRange) ? dateRange[0] : dateRange);
    let currentGap = startDate;

    for (const date of this.availableDates()) {
      if (date.getTime() < startDate.getTime()) {
        continue;
      }

      if (date.getTime() - currentGap.getTime() > dayInMs) {
        break;
      }

      currentGap = date;
      updatedDates.push(date);
    }

    this.availableDates.set(updatedDates);
  }

  onManualSyncClick(): void {
    this.manualSync.emit({
      ...this.form.value,
    } as SalesImportFormDetails);
  }

  onBranchSelected(branchId: string): void {
    this.isBranchProviderAuth.set(this.branchTenants?.length > 1);
    this.form.patchValue({ tenant: null });
    this.branchSelected.emit({ branchId });

    if (!this.isBranchProviderAuth()) {
      this.form.patchValue({ tenant: this.branchTenants.at(0) });
    }
  }

  onTenantSelected(tenant: BranchTenant): void {
    this.availableDates.set([]);
    this.form.patchValue({ dateRange: null });
    this.tenantSelected.emit({ branchId: this.form.value.branch?.id as string, tenant });
  }

  protected onToggleIgnored(): void {
    this.toggleIgnored.emit();
  }

  protected readonly getLocalizedName = getLocalizedName;

  private applyTimezoneOffset(date: Date): Date {
    return this.timeZonePipe.transform(date, this.ianaTimeZone);
  }
}
