import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { ACCOUNTING_INTEGRATION_BFF_URI } from '../config';

export class RegenerateUrlSignature {
  readonly url: string;
}
@Injectable({
  providedIn: 'root',
})
export class AccountingIntegrationService {
  protected readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(ACCOUNTING_INTEGRATION_BFF_URI);

  syncGrn(grnId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/grns/${grnId}/provider-invoices`, null);
  }

  regenerateUrlSignature(bucketName: string, filePath: string): Observable<RegenerateUrlSignature> {
    return this.httpClient.post<RegenerateUrlSignature>(`${this.bffUri}/signed/auth`, { bucketName, filePath });
  }
}
