import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { ProviderEnum } from '../../tenant';
import { SALES_IMPORTS_URI } from '../config';
import {
  AvailableSalesImportSyncDatesResponse,
  DeleteSalesTransactionsRequest,
  IgnoreTransactionsRequest,
  ManualSalesImportSyncRequest,
  MapSalesTransactionsRequest,
  ProviderSyncSalesImportRequest,
  ProviderSyncSalesImportResponse,
  SalesImport,
  SalesImportsRequestProps,
  SalesTransaction,
  SalesTransactionQueryProps,
  UnignoreTransactionsRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SalesImportService {
  private readonly httpClient = inject(HttpClient);
  private readonly uri = inject<string>(SALES_IMPORTS_URI);

  get(id: string): Observable<SalesImport> {
    return this.httpClient.get<SalesImport>(`${this.uri}/${id}`);
  }

  getMany(query: Query<SalesImport & SalesImportsRequestProps>): Observable<IQueryResponse<SalesImport>> {
    return this.httpClient.get<IQueryResponse<SalesImport>>(`${this.uri}`, {
      params: query.toQueryParams(),
    });
  }

  submit(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}/submit`, null);
  }

  uploadSheet(file: FormData): Observable<{ fileUrl: string }> {
    return this.httpClient.post<{ fileUrl: string }>(`${this.uri}/upload`, file);
  }

  manualSync(body: ManualSalesImportSyncRequest): Observable<SalesImport> {
    return this.httpClient.post<SalesImport>(`${this.uri}/manual-sync`, body);
  }

  providerSync(body: ProviderSyncSalesImportRequest): Observable<ProviderSyncSalesImportResponse> {
    return this.httpClient.post<ProviderSyncSalesImportResponse>(`${this.uri}/provider-sync`, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  mapTransactions(body: MapSalesTransactionsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/map-transactions`, body);
  }

  cancelTransactions(importId: string, transactionIds?: string[]): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${importId}/canceled-transactions-to-wastage`, null);
  }

  deleteTransactions(importId: string, body: DeleteSalesTransactionsRequest): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${importId}/delete-transactions`, { body });
  }

  ignoreTransactions(body: IgnoreTransactionsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/ignore`, body);
  }

  unignoreTransactions(body: UnignoreTransactionsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/unignore`, body);
  }

  getTransactions(
    salesImportId: string,
    query: Query<SalesTransactionQueryProps>,
  ): Observable<IQueryResponse<SalesTransaction>> {
    return this.httpClient.get<IQueryResponse<SalesTransaction>>(`${this.uri}/${salesImportId}/grouped-transactions`, {
      params: query.toQueryParams(),
    });
  }

  getSyncAvailableDates(
    retailerId: string,
    tenantId: string,
    branchId: string,
    providerBrand: ProviderEnum,
  ): Observable<AvailableSalesImportSyncDatesResponse> {
    return this.httpClient
      .post<AvailableSalesImportSyncDatesResponse>(`${this.uri}/get-sync-available-dates`, {
        retailerId,
        tenantId,
        branchId,
        providerBrand,
      })
      .pipe(catchError(e => of({ dates: [] })));
  }
}
