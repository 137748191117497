<supy-dialog
  class="supy-select-provider-branch-dialog"
  initialState="open"
  position="center"
  width="25vw"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header>
    <div class="supy-select-provider-branch-dialog__header">
      <h3 i18n="@@integrations.mapping.selectPosBranch">Select Pos Branch</h3>
      <supy-separator type="solid" class="supy-select-provider-branch-dialog__separator" />
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="supy-select-provider-branch-dialog__content">
    <supy-select placeholder="Choose Branch" (selected)="providerBranch = $event">
      <supy-select-item *ngFor="let providerBranch of providerBranches" [value]="providerBranch">
        {{ providerBranch.displayName | titlecase }}
      </supy-select-item>
    </supy-select>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-select-provider-branch-dialog__footer">
    <supy-button name="cancel" color="default" (buttonClick)="onCancel()" i18n="@@cancel">Cancel</supy-button>
    <supy-button name="action-btn" color="secondary" (buttonClick)="onSubmit()" i18n="@@select">Select</supy-button>
  </supy-dialog-footer>
</supy-dialog>
